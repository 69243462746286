<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar bg4 text-center">
      <strong class="fs-1">Soon, we'll be communicating online.</strong>
      <div class="menu">
        <div class="alink" @click="toCart">My Cart</div>
        <div class="alink">Orders</div>
        <div class="alink" @click="toAccount">Account</div>
        <div class="current alink" @click="toMessages">Messages</div>
        <div class="alink" @click="toAbout">About</div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="login psrel ">
        <div class="form-row area-one p-4">
          <div class="jumbotron psrel mb-4">
            <h4>Message</h4>
            <p class="lead mb-2">
              Please submit any questions here, We will reply as soon as
              possible.
            </p>
            <hr class="my-4" />
          </div>

          <form class="ajax-form" method="post">
            <div class="form-group mb-3">
              <label><i class="bi-chat-left-dots"></i> Content</label>
              <textarea class="form-control" rows="4" name="content"></textarea>
            </div>

            <button class="btn btn-danger">Submit</button>
          </form>


        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";


export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {

    };
  },

  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
  },

  watch: {},
  mounted() {},
  methods: {
    toOrders() {
      this.$router.push("/order/list");
    },
    toCart() {
      this.$router.push("/cart");
    },
    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAbout() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
</style>